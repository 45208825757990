import React from 'react';

//import image
import Ogi from '../assets/proto-00.png';

//motion
import { motion } from 'framer-motion';

//variants
import { fadeIn } from '../variants';

//type animation
import { TypeAnimation } from 'react-type-animation';

const Banner = () => {
  return (
    <section className="banner-inner" id="banner">
        <motion.div
        variants={fadeIn('left', 0.5)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once:false, amount: 0.1}}
        className="banner-Ogi">
          <img src={Ogi} alt="" />
        </motion.div>
        <motion.div
        variants={fadeIn('right', 1.0)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once:false, amount: 0.1}}
        className="banner-Coool">
          <p>
            <h1>COOOL COLOURS</h1>
            <h2>E S T M M X X I I I</h2>
            <h3>SUSTAINABLY-FARMED</h3>
            <h3>CLOTHING & APPAREL</h3>
          </p>
        </motion.div>
        <motion.div
      id=""
      variants={fadeIn('left', 2.0)}
      initial="hidden"
      whileInView={"show"}
      viewport={{once:false, amount: 0.05}}
      className="">
        <p class="subscribe-info"><TypeAnimation 
            sequence={[
              'information on new collections',
              3000,
              'dates & locations for pop-up studios',
              3000,
              'chances to win free merchandise',
              3000,
            ]}
            speed={70}
            className="text-accent"
            wrapper="span"
            repeat={Infinity}
            /></p>
        <form
        action="subscribe.php"
        method="post">
          <input
          required
            name="email"
            type="text"
            placeholder="email address"
            className="subscribe-input"/>
              <button type="submit" className="subscribe-btn">subscribe</button>
        </form>
    </motion.div>
    </section>
  );
};

export default Banner;