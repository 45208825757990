import React from 'react';

// link
import { Link } from 'react-scroll';

//motion
import { motion } from 'framer-motion';

//variants
import { fadeIn } from '../variants';

//import image
import logoMain from '../assets/logo-cream-trans-00.png';

const Header = () => {
  return (
    <header id="home">
      <div className="header-inner" id="header">
        <Link to="#home" className="header-link">
        <img src={logoMain} alt="" className="Coool Colours Logo"/>
        </Link>
        
        <Link to="form"  className="">
          <motion.a
          variants={fadeIn('right', 0.5)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once:false, amount: 0.7}}
          href="index.html" alt=""
          className="header-btn">
            free bag
          </motion.a>
        </Link>
      </div>
    </header>
  );
};

export default Header;