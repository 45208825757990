
import './App.css';
import Header from  './components/Header.js';
import Nav from  './components/Nav.js';
import Banner from  './components/Banner.js';
// import Main from  './components/Main.js';
// import Social from  './components/Social.js';
import Subscribe from  './components/Subscribe.js';
import Footer from  './components/Footer.js';

function App() {
  return (
    <div className="App">
      <Header />
      <Nav />
      <Banner />
      {/* <Main /> */}
      {/* <Social /> */}
      <Subscribe />
      <Footer />
    </div>
  );
}

export default App;
