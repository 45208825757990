import React from 'react';

//import icons
import { FaHouseUser, FaInstagram, FaYoutube } from 'react-icons/fa';
import { FaTree } from 'react-icons/fa';
import { FaUserAstronaut } from 'react-icons/fa';

// link
import { Link } from 'react-scroll';

//import image
// import logo from '../assets/logo.svg';

const Nav = () => {
  return (
    <nav className="nav-outer">
        <div className="nav-inner">
            <div className="nav-link">
                <a href="#header">
                    <FaHouseUser />
                </a>
            </div>
            <div className="nav-link">
                    <a href="https://instagram.com/cooolcolours" target="_blank">
                    <FaInstagram />
                    </a>
            </div>
            <div className="nav-link">
                    <a href="https://www.youtube.com/channel/UCSAdYVlOd6jKR7JjYMVQFXA" target="_blank">
                    <FaYoutube />
                    </a>
            </div>
        </div>
    </nav>
  );
};

export default Nav;