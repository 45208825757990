import React from 'react';


const Footer = () => {
  return (
    <footer>
        ©2023 COOOL COLOURS. ALL RIGHTS RESERVED.
        <br />
        SUSTAINABLY-FARMED CLOTHING & APPAREL
    </footer>
  );
};

export default Footer;