import React from 'react';

//import image
import CC from '../assets/logo-dark-trans-00.png';

//motion
import { motion } from 'framer-motion';

//variants
import { fadeIn } from '../variants';

//type animation
import { TypeAnimation } from 'react-type-animation';

const Subscribe = () => {
  return (
    <section id="form" className="form-outer">
        <motion.form
        action="process_form.php"
        method="post"
        variants={fadeIn('left', 0.5)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once:false, amount: 0.05}}
        className="free-bag-form">
          <p className ="form-title">
            <img src={CC} alt="" /><br />
      <TypeAnimation 
            sequence={[
              'submit survey to receive a free organic cotton drawstring bag',
              4000,
              '& for chance to win collection#Z3R0 | The World Is Ours | Crewneck',
              4000,
              'available in our signature MoonlitIvory™ or OnyxNoir™',
              4000,
              "deliveries begin Winter '23 (while supplies last)",
              4000,
            ]}
            speed={80}
            className=""
            wrapper="span"
            repeat={Infinity}
            />
        </p>
          <div className="">
            <input
            required
            type="text"
            name="q1" 
            placeholder="your email"
            className="form-input" />
          </div>
          <div className="">
            <input
            required
            type="text"
            name="q2"
            placeholder="favorite colour"
            className="form-input" />
          </div>
            <p class="form-question">How do you discover new fashion brands and products?</p>
          <div className="form-answers">
            <label><input type="radio" name="q3" value="New Media" required />Social Media</label><br />
            <label><input type="radio" name="q3" value="Friends" />Word of Mouth</label><br />
            <label><input type="radio" name="q3" value="Old Media" />Blogs/Magazines</label><br />
            <label><input type="radio" name="q3" value="Online Trad" />Online General Shopping Platforms</label><br />
            <label><input type="radio" name="q3" value="Online Eco" />Online Sustainable Shopping Platforms</label>
          </div>
          <p class="form-question">How do you express your individuality through your clothing choices?</p>
          <div className="form-answers">
          <label><input type="radio" name="q4" value="Colourful" required /> Bold & Colourful pieces</label><br />
          <label><input type="radio" name="q4" value="Classic" /> Classic & Timeless pieces</label><br />
          <label><input type="radio" name="q4" value="Unique" /> Artistic & Unique pieces</label><br />
          <label><input type="radio" name="q4" value="Comfort" /> Casual & Comfortable pieces</label>
          </div>
          
          <p class="form-question">What role does comfort play when you choose clothing?</p>
          <div className="form-answers">
          <label><input type="radio" name="q5" value="Very important" required /> Extremely important</label><br />
          <label><input type="radio" name="q5" value="Somewhat important" /> Important, but style matters too</label><br />
          <label><input type="radio" name="q5" value="Equally important" /> Equally important to style</label><br />
          <label><input type="radio" name="q5" value="Not very important" /> Style is more important than comfort</label>
          </div>
          
          <p class="form-question">What type of sustainable fashion items resonate with you the most?</p>
          <div className="form-answers">
          <label><input type="radio" name="q6" value="cotton" required /> Organic cotton basics</label><br />
          <label><input type="radio" name="q6" value="accessories" /> Minimalist accessories</label><br />
          <label><input type="radio" name="q6" value="active" /> Eco-friendly activewear</label><br />
          <label><input type="radio" name="q6" value="vintage" /> Vintage & second-hand pieces</label>
          </div>
          
          <p class="form-question">What influences your decision to support a sustainable fashion brand?</p>
          <div className="form-answers">
          <label><input type="radio" name="q7" value="supply chain" required /> Transparent supply chain</label><br />
          <label><input type="radio" name="q7" value="packaging" /> Eco-friendly packaging</label><br />
          <label><input type="radio" name="q7" value="labor" /> Ethical labor practices</label><br />
          <label><input type="radio" name="q7" value="philanthropy" /> Community Collaborations</label>
          </div>
          
          <p class="form-question">What size sweatshirt do you wear?</p>
          <div className="form-answers">
          <label><input type="radio" name="q8" value="x-small" required /> x-small</label><br />
          <label><input type="radio" name="q8" value="small" /> small</label><br />
          <label><input type="radio" name="q8" value="medium" /> medium</label><br />
          <label><input type="radio" name="q8" value="large" /> large</label><br />
          <label><input type="radio" name="q8" value="x-large" /> x-large</label><br />
          <label><input type="radio" name="q8" value="xx-large" /> xx-large</label>
          </div>

          <div>
            <input
            type="submit"
            value="subscribe"
            id="subscribeBtn"
            className="subscribe-btn" />
          </div>

          <div class="fine-print">
            only available while supplies last*<br />
            delivery fee waived for CA addresses**<br />
            please use valid email & confirm shipping address to receive item(s)***
          </div>
        </motion.form>
    </section>
  );
};

export default Subscribe;